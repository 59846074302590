<template>
    <div class="fl_c al_c">
        <el-carousel indicator-position="inside" arrow="never" class="swiperBox" v-if="!$common.mobile()">
            <el-carousel-item v-for="(item,index) in imgList" :key="index">
                <div @click="goPage(item)">
                    <img class="imgsty" :src="item.image" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="swiperSty1" v-if="$common.mobile()">
            <el-carousel v-if="$common.mobile()" arrow="never" trigger="click">
                <el-carousel-item v-for="item in imgList" :key="item.id">
                    <img height="100%" width="100%" :src="item.image" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <side-menu/>
        <tabs-more class="menuPos" v-if="!$common.mobile()"/>
        <tabs-more class="menuPos1" v-if="$common.mobile()"/>
        <side-list class="listPos" v-if="!$common.mobile()" :sortId="sortId1" :sortArr="sortArr1"
                   :headerList="headerList"/>
        <side-list class="listPos1" v-if="$common.mobile()" :sortId="sortId1" :sortArr="sortArr1"
                   :headerList="headerList"/>
        <div class="centerBox fl_c al_c mar_bottom"
             :style="{ 'margin-top': $common.mobile() ? '0' : '','height': $common.mobile() ? '400px' : '' }">
            <title-components :title="$t('index.homeNews')" :sortId.sync="sortId3" :activeId.sync="activeId3"
                              @getFormalName="getFormalName" componentId="component1"/>
            <div class="fl_r ju_c padB" v-if="!$common.mobile()">
                <div class="childBox">
                    <div class="more" style="cursor: pointer" @click="moreList(titleList3,activeId3)" v-if="titleList3.length>8">
                        {{ $t('index.more') }}>
                    </div>
                    <div v-for="(item,index) in titleList3.slice(0,8)" :key="index" class="fl_r al_c article-list"
                         @click="goPage(item)">
                        <div class="bagTitle">
                            {{!activeId3?$t('index.homeNews'):formalName1}}
                        </div>
                        <div class="line"></div>
                        <div class="fl_r ju_b" style="width: 80%">
                            <div class="titleName">{{item.title}}</div>
                            <div class="fl_r ju_b">
                                <div class="titleDate">{{item.year}}</div>
                                <i slot="reference" class="el-icon-arrow-right"/>
                            </div>
                        </div>
                    </div>
                    <div style="border-bottom: 1px solid #E4E4E4;"></div>
                </div>
                <div class="childBox">
                    <el-carousel indicator-position="inside" arrow="never" class="swiperBox1">
                        <el-carousel-item v-for="(item,index) in sortArr2" :key="index">
                            <div @click="goPage(item)">
                                <img class="imgsty" :src="item.titleImg" alt="">
                                <div class="grayBox"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="fl_r ju_c padB" v-if="$common.mobile()">
                <div class="childBox">
                    <div class="more" style="cursor: pointer" @click="moreList(titleList3)" v-if="titleList3.length>5">
                        {{ $t('index.more') }}>
                    </div>
                    <div v-for="(item,index) in titleList3.slice(0,5)" :key="index" class="fl_r al_c"
                    >
                        <div class="fl_r ju_b" style="width: 100%">
                            <div class="titleName mar_top" @click="goPage(item)">{{item.title}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="centerBox fl_c al_c mar_bottom"
             :style="{ 'margin-top': $common.mobile() ? '0' : '0','height': $common.mobile() ? '400px' : '' }">
            <title-components :title="$t('index.Industrial')" :sortId.sync="sortId5" :activeId.sync="activeId5"
                              @getFormalName="getFormalName" componentId="component2"/>
            <div class="fl_r ju_c padB">
                <div class="childBox" v-if="!$common.mobile()">
                    <div class="more" style="cursor: pointer" @click="moreList(titleList5,activeId5)" v-if="titleList5.length>8">
                        {{ $t('index.more') }}>
                    </div>
                    <div v-for="(item,index) in titleList5.slice(0,8)" :key="index" class="fl_r al_c article-list1"
                         @click="goPage(item)">
                        <div class="bagTitle">
                            {{!activeId5?$t('index.Industrial'):formalName}}
                        </div>
                        <div class="line"></div>
                        <div class="fl_r ju_b" style="width: 500px">
                            <div class="titleName">{{item.title}}</div>
                            <div class="fl_r ju_b">
                                <div class="titleDate">{{item.year}}</div>
                                <i slot="reference" class="el-icon-arrow-right"/>
                            </div>
                        </div>
                    </div>
                    <div style="border-bottom: 1px solid #E4E4E4;"></div>
                </div>
                <div class="childBox" v-if="$common.mobile()">
                    <div class="more" style="cursor: pointer" @click="moreList(titleList5)" v-if="titleList5.length>5">
                        {{ $t('index.more') }}>
                    </div>
                    <div v-for="(item,index) in titleList5.slice(0,5)" :key="index" class="fl_r al_c"
                         @click="goPage(item)">
                        <div class="fl_r ju_b" style="width: 100%">
                            <div class="titleName mar_top" @click="goPage(item)">{{item.title}}</div>
                        </div>
                    </div>
                </div>
                <div class="childBox fl_r" v-if="!$common.mobile()" style="margin-left: 20px">
                    <div class="hotPoiont fl_c ju_c">
                        <div class="topic">{{$t('index.hotspots')}}</div>
                        <div class="title"
                             v-if="sortArr4&&sortArr4.length>0" @click="goPage(sortArr4[0])">{{sortArr4[0].title}}
                        </div>
                        <div class="english">Topic Hotspots</div>
                    </div>
                    <div class="fl_c">
                        <div class="tophot fl_c ju_c pad_about">
                            <div class="topic" style="color:rgba(173, 50, 50, 0.70)">{{$t('index.hotspots')}}</div>
                            <div class="title" style="color:rgba(70, 70, 70, 1)"
                                 v-if="sortArr4&&sortArr4.length>1" @click="goPage(sortArr4[1])">{{sortArr4[1].title}}
                            </div>
                            <div class="english" style="color: rgba(154, 19, 19, 0.16)">Topic Hotspots</div>
                        </div>
                        <div class="bothot fl_c ju_c pad_about">
                            <div class="topic" style="color:rgba(85, 0, 0, 0.76)">{{$t('index.hotspots')}}</div>
                            <div class="title" style="color:rgba(255, 207, 207, 1)"
                                 v-if="sortArr4&&sortArr4.length>2" @click="goPage(sortArr4[2])">{{sortArr4[2].title}}
                            </div>
                            <div class="english" style="color: rgba(0, 0, 0, 0.16)">Topic Hotspots</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="centerBox fl_c al_c"
             :style="{ 'margin-top': $common.mobile() ? '0' : '0','height': $common.mobile() ? 'auto' : '' }">
            <title-components :title="$t('index.business')" componentId="component3"/>
            <div class="fl_r al_c ju_c ju_r fl_w fourBox" v-if="!$common.mobile()">
                <div class="fourItem fl_c ju_c al_c" v-for="(item,index) in sortArr6" :key="index"
                     @click="goPage(item)">
                    <div class="title">{{item.title}}</div>
                    <!--                    <div class="name">Spot Trading</div>-->
                </div>
            </div>
            <div class="fl_r al_c ju_c ju_r fl_w fourBox" v-if="$common.mobile()" style="width: 100%;height: auto">
                <div class="fourItem fl_c ju_c al_c" v-for="(item,index) in sortArr6" :key="index"
                     style="width: 100%;margin-right: 0"
                     @click="goPage(item)">
                    <div class="title">{{item.title}}</div>
                    <!--                    <div class="name">Spot Trading</div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getSwiper, getarticlesInfo, getColumnTree, getPreSellList} from '../../utils/api/index.js'
    import sideMenu from "../../components/sideMenu/sideMenu";
    import tabsMore from "../../components/tabsMore/tabsMore";
    import sideList from "../../components/tabsMore/sideList";
    import titleComponents from "../../components/titleComponents/titleComponents";

    export default {
        name: "index",
        components: {sideMenu, tabsMore, sideList, titleComponents},
        data() {
            return {
                imgList: [],
                headerList: [],

                sortId1: [],
                sortArr1: [],
                sortId2: [],
                sortArr2: [],
                sortId3: [],
                sortArr3: [],
                activeId3: null,
                titleList3: [],

                sortId4: [],
                sortArr4: [],

                sortId5: [],
                sortArr5: [],
                activeId5: null,
                titleList5: [],

                sortId6: [],
                sortArr6: [],
                formalName: null,
                formalName1: null
            }
        },
        mounted() {
            this.$store.commit('setWeixing', false);
            this.getColumnTree()
        },
        watch: {
            sortArr3: {
                handler(newVal, oldVal) {
                    if (newVal && newVal.length > 0) {
                        newVal.forEach(item => {
                            item.year = item.showTime.substring(0, 10)
                            if (item.columnId == this.activeId3) {
                                this.titleList3.push(item)
                            } else {
                                this.titleList3 = this.sortArr3
                            }
                        })
                    }
                },
                immediate: true
            },
            activeId3(val) {
                this.titleList3 = []
                if (val == 'onlyId') {
                    this.getPreSellList()
                } else {
                    if (this.sortArr3 && this.sortArr3.length > 0) {
                        this.sortArr3.map(item => {
                            if (JSON.stringify(item.columnId) == this.activeId3) {
                                this.titleList3.push(item)
                            }
                        })
                    }
                }

            },
            sortArr5: {
                handler(newVal, oldVal) {
                    if (newVal && newVal.length > 0) {
                        newVal.forEach(item => {
                            if (item.columnId == this.activeId5) {
                                this.titleList5.push(item)
                            } else {
                                this.titleList5 = this.sortArr5
                            }
                        })
                    }
                },
                immediate: true
            },
            activeId5(val) {
                this.titleList5 = []
                if (this.sortArr5 && this.sortArr5.length > 0) {
                    this.sortArr5.map(item => {
                        if (JSON.stringify(item.columnId) == this.activeId5) {
                            this.titleList5.push(item)
                        }
                    })
                }
            },
        },
        methods: {
            moreList(list,id) {
                let nowID=id?id:list[0].columnId
                this.headerList.map((item) => {
                    item.subArticles.map((child) => {
                        if (child.id.toString() == nowID) {
                            this.$store.commit("setFormalItem", item);
                            this.$store.commit("setChildItem", child);
                            this.$router.push({
                                path: "/secondPage",
                                query: {columnId: child.id},
                            });
                        }
                    });
                });
            },
            async getPreSellList() {
                let res = await getPreSellList()
                res.data.map(item => {
                    item.title = item.presellTitle
                    item.isPre = true
                    if (item.auditTime) {
                        item.year = item.auditTime.substring(0, 10)
                        //item.date = item.auditTime.substring(5, 10)
                    }
                })
                this.titleList3 = res.data
            },
            getFormalName(val, componentId) {
                if (componentId === 'component1') {
                    this.formalName1 = val;
                } else if (componentId === 'component2') {
                    this.formalName = val;
                }
            },
            goSecondPage(id) {
                this.headerList.map(item => {
                    item.subArticles.map(child => {
                        if (child.id.toString() == id) {
                            this.$store.commit('setFormalItem', item);
                            this.$store.commit('setChildItem', child);
                            this.$router.push({path: '/secondPage', query: {columnId: child.id}})
                        }
                    })
                })
            },
            async getColumnTree() {
                let res = await getColumnTree()
                res.data.map(async item => {
                    this.headerList = res.data
                    if (item.columnType == '01') {
                        let arr1 = []
                        let arr2 = []
                        let arr3 = []
                        let arr4 = []
                        let arr5 = []
                        let arr6 = []
                        this.getSwiper(item.id)
                        item.subArticles.map(async child => {
                            if (child.sequence == 1) {
                                this.sortId1.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr1.push(child.id.toString())
                            }
                            if (child.sequence == 2) {
                                this.sortId2.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr2.push(child.id.toString())
                            }
                            if (child.sequence == 3) {
                                this.sortId3.push({
                                    id: child.id.toString(),
                                    name: child.name,
                                    selected: false,
                                })
                                if (this.sortId3.length > 0) {
                                    if (this.formalName1 == null) {
                                        this.formalName1 = this.sortId3[0].name
                                    }
                                    // this.sortId3[0].selected = true
                                    // this.activeId3 = this.sortId3[0].id
                                }
                                arr3.push(child.id.toString())
                            }
                            if (child.sequence == 4) {
                                this.sortId4.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr4.push(child.id.toString())
                            }
                            if (child.sequence == 5) {
                                this.sortId5.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                if (this.sortId5.length > 0) {
                                    if (this.formalName == null) {
                                        this.formalName = this.sortId5[0].name
                                    }
                                    // this.sortId5[0].selected = true
                                    // this.activeId5 = this.sortId5[0].id
                                }
                                arr5.push(child.id.toString())
                            }
                            if (child.sequence == 6) {
                                this.sortId6.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr6.push(child.id.toString())
                            }
                        })
                        this.sortId3.push({
                            id: 'onlyId',
                            name: "🔈" + this.$t('index.announcement'),
                            selected: false,
                        })
                        let res1 = await getarticlesInfo({
                            columnId: arr1.toString()
                        })
                        this.sortArr1 = res1.data

                        let res2 = await getarticlesInfo({
                            columnId: arr2.toString()
                        })
                        this.sortArr2 = res2.data

                        let res3 = await getarticlesInfo({
                            columnId: arr3.toString()
                        })
                        res3.data.map(item => {
                            item.year = item.showTime.substring(2, 4)
                            item.date = item.showTime.substring(5, 10)
                        })
                        this.sortArr3 = res3.data
                        let res4 = await getarticlesInfo({
                            columnId: arr4.toString()
                        })
                        this.sortArr4 = res4.data

                        let res5 = await getarticlesInfo({
                            columnId: arr5.toString()
                        })
                        res5.data.map(item => {
                            item.year = item.showTime.substring(0, 10)
                        })
                        this.sortArr5 = res5.data

                        let res6 = await getarticlesInfo({
                            columnId: arr6.toString()
                        })
                        this.sortArr6 = res6.data
                    }
                })
            },
            async getSwiper(id) {
                let res = await getSwiper({
                    columnId: id
                })
                this.imgList = res.data
            },
            goPage(item) {
                if(item.belongType=='01'){
                    this.headerList.map((item1) => {
                        item1.subArticles.map((child) => {
                            if (child.id.toString() == '395') {
                                this.$store.commit("setFormalItem", item1);
                                this.$store.commit("setChildItem", child);
                                this.$router.push({
                                    path: "/secondPage",
                                    query: {columnId: child.id},
                                });
                            }
                        });
                    });
                    return
                }
                if (item.isPre) {
                    this.$router.push({
                        path: "/detailPage",
                        query: {
                            articleId: item.id,
                            isPre: true
                        }
                    });
                } else {
                    this.$router.push({
                        path: "/detailPage",
                        query: {
                            articleId: item.id,
                            columnId: item.columnId
                        }
                    });
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    .swiperBox {
        width: 100%;
        height: 800px;
        position: absolute;
        top: 0;
        z-index: 0;

        ::v-deep .el-carousel__container {
            height: 800px;
        }
    }

    .swiperBox1 {
        width: 100%;
        height: 500px;
        z-index: 0;
        margin-top: 30px;

        ::v-deep .el-carousel__container {
            height: 500px;
        }

        .imgsty {
            width: 739px;
            height: 500px;
            margin-left: 20px;
        }
    }

    ::v-deep .el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 10px;
    }


    /**********************************phone**********************/
    .swiperSty1 {
        width: 100%;

        .el-carousel__item {
            color: #475669;
            height: 175px;
        }

        .el-carousel {
            margin-top: 50px;
            /*position: absolute;*/
            /*top: 0;*/
            /*z-index: 0;*/
            width: 100%;
            height: 175px !important;
        }

        ::v-deep .el-carousel__container {
            height: 175px !important;
        }
    }

    .menuPos {
        position: absolute;
        width: 332px !important;
        height: 253px !important;
        background: rgba(255, 255, 255, 0.85);
        opacity: 1;
        right: 120px;
        top: 100px;
    }

    .menuPos1 {
        width: 100% !important;
        height: 253px !important;
        background: rgba(255, 255, 255, 0.85);
        opacity: 1;
    }

    .listPos {
        position: absolute;
        width: 332px !important;
        height: 377px !important;
        background: rgba(255, 255, 255, 0.85);
        opacity: 1;
        right: 120px;
        top: 370px;
        z-index: 999;
    }

    .listPos1 {
        width: 100% !important;
        height: 377px !important;
        background: rgba(255, 255, 255, 0.85);
        opacity: 1;
        z-index: 999;

    }

    .centerBox {
        width: 100%;
        height: 763px;
        margin-top: 720px;

        .padB {
            width: 80%;
            margin-top: 30px;

            .childBox {
                width: 50%;
                margin: 0;

                .more {
                    width: 100%;
                    height: 32px;
                    line-height: 32px;
                    font-size: 16px;
                    color: rgba(174, 70, 70, 0.8);
                    border-bottom: 1px solid #E4E4E4;
                    text-align: end;
                }

                .bagTitle {
                    width: 300px;
                    height: 108px;
                    font-size: 24px;
                    font-family: DINPro-Bold, DINPro;
                    font-weight: bold;
                    color: #B2B2B2;
                    line-height: 78px;
                    letter-spacing: 2px;
                    text-align: center;
                    padding: 15px 0 15px 15px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }

                .date {
                    width: auto;
                    height: 25px;
                    font-size: 20px;
                    font-family: DINPro-Medium, DINPro;
                    font-weight: 500;
                    color: #C7C7C7;
                    line-height: 23px;
                    text-align: center;
                }

                .year {
                    text-align: center;
                    width: auto;
                    height: 82px;
                    font-size: 64px;
                    font-family: DINPro-Bold, DINPro;
                    font-weight: bold;
                    color: #C7C7C7;
                    line-height: 75px;
                }

                .line {
                    width: 1px;
                    height: 30px;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    border: 1px solid #E4E4E4;
                    margin: 0 20px;
                }

                .titleName {
                    width: 300px;
                    height: 26px;
                    font-size: 20px;
                    font-family: DINPro-Medium, DINPro;
                    font-weight: 500;
                    color: #616161;
                    line-height: 23px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .titleDate {
                    width: 100px;
                    font-size: 16px;
                    font-family: DINPro-Medium, DINPro;
                    font-weight: 500;
                    color: #828282;
                    line-height: 23px;
                    cursor: pointer;
                    margin-right: 10px;

                }

                .titleName:hover {
                    color: #B72D27;
                }

                i {
                    width: 14px;
                    height: 5px;
                    margin-top: 3px;
                }

                .hotPoiont {
                    width: 300px;
                    height: 540px;
                    background: #850416;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    margin-top: 30px;
                    padding: 0 30px;
                    cursor: pointer;

                }

                .topic {
                    width: 101px;
                    height: 28px;
                    border-bottom: 2px #B70C04 solid;
                    font-size: 20px;
                    font-weight: 800;
                    color: rgba(255, 99, 99, 0.76);
                    line-height: 23px;
                    letter-spacing: 1px;

                }

                .title {
                    width: auto;
                    height: auto;
                    font-size: 25px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFCFCF;
                    line-height: 30px;
                    margin: 18px 0;

                }

                .english {
                    width: auto;
                    height: 40px;
                    font-size: 29px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.16);
                    line-height: 33px;
                }

                .tophot {
                    margin-top: 30px;
                    width: 401px;
                    height: 260px;
                    border-radius: 0px 0px 0px 0px;
                    background: RGBA(234, 222, 212, 1);
                    opacity: 1;
                    margin-left: 20px;
                    cursor: pointer;
                }

                .bothot {
                    width: 401px;
                    height: 260px;
                    background: linear-gradient(111deg, #DC433C 0%, rgba(220, 67, 60, 0) 100%);
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    margin-top: 20px;
                    margin-left: 20px;
                    cursor: pointer;
                }
            }
        }


    }

    .fourBox {
        margin-top: 10px;
        width: 1227px;
        height: 560px;

        .fourItem {
            width: 590px;
            height: 260px;
            background: rgba(133, 4, 22, 0.85);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            cursor: pointer;

            .title {
                width: auto;
                height: 56px;
                font-size: 40px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 47px;
            }

            .name {
                width: auto;
                height: 28px;
                font-size: 20px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #DF3027;
                line-height: 23px;
            }
        }

        .fourItem:nth-child(odd) {
            margin-right: 20px;
        }
    }

    .article-list {
        height: 62px;
    }

    .article-list1 {
        height: 67px;
    }
</style>
