<template>
    <div class="sidebar" v-if="!$common.mobile()">
        <div class="firstBox">
            <span>{{$t('index.express')}}</span>
            <i icon="el-icon-plus"></i>
        </div>
        <ul v-if="menuList.subArticles&&menuList.subArticles.length>0">
            <li v-for="menuItem in menuList.subArticles"
                :key="menuItem.id"
                @mouseenter="showSubMenu(menuItem)"
                @mouseleave="hideSubMenu(menuItem)"
                @click.stop="goPage(menuItem)"
                class="menu-item">
                {{ menuItem.name }}
                <ul class="submenu">
                    <li v-for="subItem in menuItem.subArticles" :key="subItem.id" @click="goPage(subItem)"
                        class="submenu-item">
                        {{ subItem.name}}
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
    import {getColumnTree} from '../../utils/api/index.js'

    export default {
        data() {
            return {
                menuList: [],
            };
        },
        mounted() {
            this.getColumnTree()
        },
        methods: {
            goPage(item) {
                if (item.linkAddr && item.linkAddr.substring(0, 5)) {
                    window.open(item.linkAddr && item.linkAddr);
                } else {
                    this.$router.push(item.linkAddr);
                }

            },
            showSubMenu(menuItem) {
                menuItem.submenuVisible = true;
            },
            hideSubMenu(menuItem) {
                menuItem.submenuVisible = false;
            },
            async getColumnTree() {
                this.menuList = []
                let res = await getColumnTree();
                res.data = res.data.map(item => {
                    if (item.columnType === '03') {
                        this.menuList = item
                    }
                });
            },
        }
    };
</script>

<style scoped lang="scss">
    .sidebar {
        width: 332px;
        height: 57px;
        background-color: #333;
        color: #fff;
        position: absolute;
        left: 120px;
        top: 100px;
        text-align: center;

        ul {
            width: 332px;
            height: auto;
            background: rgba(194, 7, 7, 0.86);
        }

        .firstBox {
            width: 332px;
            height: 57px;
            background: linear-gradient(180deg, #FFB660 0%, #F0A54E 100%);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            text-align: center;
            line-height: 57px;

            span {
                width: 100px;
                height: 26px;
                font-size: 20px;
                font-family: DINPro-Medium, DINPro;
                font-weight: 500;
                color: #8E0600;
                line-height: 23px;
            }
        }
    }

    .menu-item {
        height: 57px;
        /*line-height: 57px;*/
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        ul {
            width: 332px;
            height: auto;
            background: rgba(153, 5, 5, 0.86);
        }
    }

    .menu-item:hover {
        background-color: rgba(153, 5, 5, 0.86);
    }

    .submenu {
        position: absolute;
        top: 0;
        left: 100%;
        display: none;
        background-color: #555;
        color: #fff;
        width: 332px;
        height: 57px;
        text-align: center;
    }

    .menu-item:hover .submenu {
        display: block;
    }

    .submenu-item {
        height: 57px;
        /*line-height: 57px;*/
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .submenu-item:hover {
        background-color: rgba(235, 26, 32, 0.86);
    }
</style>
