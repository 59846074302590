<template>
    <div class="fl_c">
        <div class="fl_r ju_b titleTop pos_r">
            <el-tabs v-model="activeName" @tab-click="handleClick" type="card"
                     class="" style="width: 100%;">
                <el-tab-pane :label="item.name" :name="item.name" v-for="(item,index) in priceList"
                             :key="index">
                    <div class="fl_c al_c mar_top">
                        <div class="text">
                            <span class="mar_right">{{item.name}}</span>
                            <span style="color: rgba(129, 129, 129, 1)"> {{ $t('index.money') }}/{{item.unitQuantifier}}</span>
                        </div>
                        <div class="price">{{item.price}}</div>
                        <div class="fl_r al_c icon">
                            <div>
                                <span>{{item.settlePl}}</span>
                                <i slot="reference" class="el-icon-top" style="color: red" v-if="item.settlePl>0"/>
                                <i slot="reference" class="el-icon-bottom" v-else/>
                            </div>
                            <div>
                                <span>{{ formatPercentage(item.settleRa) }}</span>
                                <i slot="reference" class="el-icon-top" style="color: red" v-if="item.settleRa>0"/>
                                <i slot="reference" class="el-icon-bottom" v-else/>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="more pos_a" style="right: 0;cursor: pointer" @click="goPage">{{ $t('index.more') }}</div>
        </div>
    </div>
</template>

<script>
    import {getPrice} from '@/utils/api/index.js'

    export default {
        name: "tabsMore",
        components: {},
        data() {
            return {
                activeName: 0,
                priceList: [],
            }
        },
        mounted() {
            this.getPrice()
            this.timer = setInterval(() => {
                this.getPrice();
            }, 5000);
        },
        methods: {
            formatPercentage(value) {
                return (value * 100).toFixed(2) + "%";
            },
            async getPrice() {
                let res = await getPrice()
                this.priceList = res.data
                if (res.data && res.data.length > 0 && this.activeName == 0) {
                    this.activeName = res.data[0].name
                }
            },
            handleClick() {

            },
            goSecondPage() {

            },
            goPage() {
                this.$router.push({
                    path: "/secondPage",
                    query: {ifPrice: true, columnId: 483},
                });
            }
        },
    }
</script>

<style scoped lang="scss">
    .titleTop {
        height: 43px;
        width: 100%;
        color: #ffffff;
        background-color: #B70C04;

        .more {
            margin-right: 15px;
            color: #ffffff;
            font-size: 15px;
            line-height: 43px;
        }
    }


    ::v-deep .el-tabs__active-bar {
        background-color: #B70C04;
    }

    ::v-deep .el-tabs__item.is-active {
        color: #ffffff;
    }

    ::v-deep .el-tabs__item:hover {
        color: #ffffff;
    }

    ::v-deep .el-tabs__item {
        height: 43px;
        line-height: 43px;
        color: rgba(255, 255, 255, 0.36);
    }

    ::v-deep .el-tabs__header {
        margin-bottom: 8px;
    }

    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
        border: none;
    }

    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
        border-left: 0;
    }

    .text span {
        width: auto;
        height: 24px;
        font-size: 20px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 23px;
    }

    .price {
        width: auto;
        height: 52px;
        font-size: 48px;
        font-family: DINPro-Medium, DINPro;
        font-weight: 500;
        color: #FF4C34;
        line-height: 56px;
        margin: 15px 0;
    }

    .icon {
        width: auto;
        height: 24px;
        font-size: 22px;
        font-family: DINPro-Regular, DINPro;
        font-weight: 400;
        color: #6B6B6B;
        line-height: 26px;

        i {
            margin-right: 10px;
        }

        span {
            margin-left: 10px;
        }
    }

    ::v-deep .el-tabs__nav-wrap span {
        color: white;
        font-size: 20px;
        font-weight: bold;
    }

    .marlpx {
        margin-left: 80px;
    }

    ::v-deep .el-tabs__nav-wrap.is-scrollable {
        padding: 1px 20px;
    }

    ::v-deep .el-tabs__header {
        width: 80%;

    }
</style>
