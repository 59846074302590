<template>
    <div class="fl_c">
        <div class="fl_r ju_b titleTop pos_r">
            <el-tabs v-model="activeName" @tab-click="handleClick" type="card"
                     class="" style="width: 100%">
                <el-tab-pane :label="item.name" :name="item.id"
                             v-for="(item, index) in sortId" :key="index">
                    <div class="fl_c al_c mar_top">
                        <div v-for="(item1, index1) in titleList.slice(0, 7)" :key="index1"
                             class="listSty" @click="goPage(item1)">
                            {{ item1.title }}
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="more pos_a" style="right: 0; cursor: pointer" @click="goSecondPage">
                {{ $t('index.more') }}>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sideList",
        components: {},
        props: {
            sortId: {
                type: Array,
            },
            sortArr: {
                type: Array,
            },
            headerList: {
                type: Array,
            },
        },
        data() {
            return {
                activeName: null,
                titleList: [],
            };
        },
        mounted() {
        },
        watch: {
            sortId: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal.length > 0) {
                        this.activeName = newVal[0].id;
                    }
                },
            },
            sortArr: {
                handler(newVal, oldVal) {
                    if (newVal && newVal.length > 0) {
                        newVal.forEach((item) => {
                            if (item.columnId == this.activeName) {
                                this.titleList.push(item);
                            }
                        });
                    }
                },
                immediate: true,
            },
        },
        methods: {
            handleClick(tab, event) {
                this.titleList = [];
                if (this.sortArr && this.sortArr.length > 0) {
                    this.sortArr.map((item) => {
                        if (JSON.stringify(item.columnId) == this.activeName) {
                            this.titleList.push(item);
                        }
                    });
                }
            },
            goSecondPage() {
                this.headerList.map((item) => {
                    item.subArticles.map((child) => {
                        if (child.id.toString() == this.activeName) {
                            this.$store.commit("setFormalItem", item);
                            this.$store.commit("setChildItem", child);
                            this.$router.push({
                                path: "/secondPage",
                                query: {columnId: child.id},
                            });
                        }
                    });
                });
            },
            goPage(item) {
                this.$router.push({
                    path: "/detailPage",
                    query: {
                        articleId: item.id,
                        columnId: item.columnId,
                        ishide: true,
                    },
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .titleTop {
        height: 43px;
        width: 100%;
        color: #ffffff;
        background-color: #b70c04;

        .more {
            margin-right: 15px;
            color: #ffffff;
            font-size: 15px;
            line-height: 43px;
        }
    }

    ::v-deep .el-tabs__active-bar {
        background-color: #b70c04;
    }

    ::v-deep .el-tabs__item.is-active {
        color: #ffffff;
    }

    ::v-deep .el-tabs__item:hover {
        color: #ffffff;
    }

    ::v-deep .el-tabs__item {
        height: 43px;
        line-height: 43px;
        color: rgba(255, 255, 255, 0.36);
    }

    ::v-deep .el-tabs__header {
        margin-bottom: 8px;
    }

    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
        border: none;
    }

    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
        border-left: 0;
    }

    .listSty {
        width: 90%;
        color: rgba(107, 107, 107, 1);
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }
</style>
