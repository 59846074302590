<template>
    <div>
        <div class="fl_r al_c padA" style="width: 80%" v-if="!$common.mobile()">
            <div class="title">
                <span>{{title}}</span>
                <span>News</span>
            </div>
            <i slot="reference" class="el-icon-arrow-right"/>
            <div class="fl_r">
                <div v-for="(item,index) in arr" :key="index" class="itemSty"
                     :style="{'color': (item.selected ? '#B70C04':'')}"
                     @click="mouseenter(item)" @mouseleave="mouseleave(item)">{{item.name}}
                </div>
            </div>
        </div>
        <div class="fl_c al_c " style="width: 100%" v-if="$common.mobile()">
            <div class="fl_r al_c mar_bottom">
                <div class="title">
                    <span>{{title}}</span>
                    <span>News</span>
                </div>
                <i slot="reference" class="el-icon-arrow-right"/>
            </div>
            <div class="fl_r xflow">
                <div v-for="(item,index) in arr" :key="index" class="itemSty"
                     :style="{'color': (item.selected ? '#B70C04':'')}"
                     @click="mouseenter(item)" @mouseleave="mouseleave(item)">{{item.name}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "titleComponents",
        components: {},
        props: {
            title: {
                type: String,
                default: ''
            },
            sortId: {
                type: Array,
            },
            activeId: {
                type: String,
            },
            componentId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                arr: this.sortId,
                activeName: null,
            }
        },
        mounted() {

        },
        watch: {
            activeName(val) {
                this.arr.map(item => {
                    item.selected = item.name == val
                    this.$forceUpdate()
                })
            },
        },
        methods: {
            mouseenter(item) {
                this.activeName = item.name
                this.$emit('getFormalName', this.activeName, this.componentId);
                this.$emit('update:activeId', item.id)
                this.$set(item, 'selected', true)
                this.$emit('update:sortId', this.arr)
            },
            mouseleave(item) {

            },
        },
    }
</script>

<style scoped lang="scss">
    .padA {
        margin: 58px 122px 0px;
        border-bottom: 2px #F2F2F2 solid;

        i {
            width: 14px;
            height: 5px;
            line-height: 5px;
            color: #B70C04;;
            padding: 0 40px;
        }
    }

    .title {

        text-overflow: ellipsis;
        white-space: nowrap;
        padding-bottom: 4px;
        border-bottom: 2px #B70C04 solid;

        span:first-child {
            width: auto;
            height: 45px;
            font-size: 32px;
            font-weight: 500;
            color: #454545;
            line-height: 38px;
        }

        span:last-child {
            width: 82px;
            height: 45px;
            font-size: 32px;
            font-weight: 500;
            color: rgba(183, 12, 4, 0.16);
            line-height: 38px;
            padding-left: 15px;
        }


    }

    .xflow {
        width: 300px;
        overflow: auto;
    }

    .itemSty {
        width: auto;
        height: 28px;
        font-size: 20px;
        font-weight: 500;
        color: #454545;
        line-height: 23px;
        padding: 0 15px;
        cursor: pointer;
        white-space: nowrap;
    }

    ::-webkit-scrollbar {
        width: 0 !important;
    }

    ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
    }
</style>
